@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Rubik', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  scroll-behavior: smooth;
}

.stylePopover .ant-popover-inner-content {
  padding: 0px !important;
}

.stylePopover .ant-popover-inner {
  border-radius: 20px !important;
  background-color: rgb(255 255 255 / 92%);
}


{/* style popover profile */}
.stylePopoverProfile .ant-popover-inner  {
  border-radius: 20px !important;
  background-color: rgb(255 255 255);
  width: 257px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.15);
}

.stylePopoverProfile .ant-popover-inner-content {
  padding: 22px 16px !important;
}


.pac-container {
  background-color: #fff;
  border-radius: 10px;
  border-top: 1px;
  font-family: 'Rubik', sans-serif;
  box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
  overflow: hidden;
  border: 1px solid #dfe8f1;
}

.pac-item {
  border-top: 1px solid #dfe8f1;
}
.hdpi.pac-logo:after {
  background-image: unset;
}
